import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "paragraph"
    }}>{`Paragraph`}</h1>
    <p>{`Body copy - usually 18pt, maybe 16pt (15px is default)
Use golden ratio for line height`}</p>
    <p>{`Mobile body text - multiply by .6 (need to verify)`}</p>
    <p>{`Recommended body copy color on white - black 90%, #747474, #4a4a4a`}</p>
    <p>{`Space between paragraphs? 20 px`}</p>
    <p>{`Default use of `}<inlineCode parentName="p">{`<strong>`}</inlineCode>{` variation within a page or site`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`<p>paragraph test here.</p>`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      